.homepage {
}

.whatDefinesUs {
  
}
.history {
     border-left: solid 2px purple;
     margin:1rem 4rem;
     width: fit-content;
    transform: translate(0px, 0px) !important;
}
.history div:before {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: solid rgb(255, 255, 255) 3px;
  position: absolute;
  top:10px;
  left: -21px;
  background-color: purple;
}

.productoffers{
  margin:.8rem;
  .product{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    outline:2rem;
    border:none;
    .product_card {
      background-color: purple;
      width: 100%;
      height: 100%;
      .heading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
      img {
      background-color: white;
      margin:2rem;
      padding:.5rem; 
      border-radius: 50%;
      width: 7rem;
    }
    h5{
      font-weight: 600;
      font-size: 18px;
      text-align: center;
    }
       }
    .list {
      margin:1rem 2rem 0 1%;
      display: flex;
      justify-content: start;
      width: 100%;
     ul{
      .li{
        margin:.4rem;

        span{
         align-self:center;
         text-align :center;
        }
         &::before{
        content: "*";
        margin:.4rem;
      }
      }
      h5 {
        font-size: 17px;
        font-weight: 600;
        text-align: start;
        margin: 1rem 0 .3rem .8rem;
      }
     }
    }
    @media screen and (max-width:'765px'){
       padding:1rem
    }
    }
    .gap {
      height: 2rem;
      background-color: purple;
      width: 100%;
      margin: .5rem 0;
      
    }
  }

  
}
