@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
.splashScreen {
    z-index:100;
    background: rgb(186,167,203);
    background: radial-gradient(circle, rgba(186,167,203,1) 6%, rgba(171,137,199,1) 54%);
    height:100vh;
    position: fixed;
    left:0;
    top:0;
    width:100%;
    display:flex;
    justify-content: center;
    align-items:center;
    font-family: 'Bebas Neue', cursive;
}
#Vector {
   animation: vector 2s ease-in-out;
}

@keyframes vector {
    from {
        opacity: 0;
    }
    to {
        opacity:1;
    }
}

.word {
     text-align: center;
}