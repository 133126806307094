$primary: #662f94;
nav { 
    position: fixed;
    height: 80px;
    width:100%;
    background-color: #ffffff;
    z-index: 100;

}

label.logo{
    font-size: 30px;
    font-family:Roboto;
}
.nav-list { 
    list-style: none;
    padding : 0 ;
    float : right ;
    margin: .6rem .1rem 0rem 0rem;
    height: 60px;
    
}

.nav-list>li {
    display: inline-block;
    margin: 0 0;
    height: 70px;
    line-height: 60px;
    padding: 0 1rem !important;
    bottom:0;
    transition: all ease-out .5s;
    div {
        display: flex;
        flex-direction: row;
    }

    &:hover,:active {
        background-color: purple;
        transition: all ease-in .1s;
        color : white;
        .portals {
            display: block;
            transition: all ease-in-out .5s;
            margin:.1rem;
            padding:0;
            width: 150px;
        }
         a {
            color: rgb(255, 255, 255) !important;
         }
    }
    
    a { 
        color: black;
        text-decoration: none;
        padding: .5rem 0rem .2rem 0rem;
        font-size: 12px;
       
    }
    
}
nav .fa,.fas { 
    color:rgb(92, 6, 92) !important ;
    font-size: 30px;
}
nav .icon {
    display: none;
}
.portals {
    position: absolute;
    top: 80px;
    display: none;
}

.portals li {
    height: auto !important;
    border: solid rgba(255, 255, 255, .5) 1px;
    list-style: none;
    background-color: purple;
    width: 100%;
    text-align: center;
    margin:.1rem;
}

@media (max-width:768px){
    nav {
        position: fixed;
    }
    nav .icon {
        display: inline;
        color: #662f94;
        float: right;
        transform: translate(-40%,40%);
        transition: all ease 1s;
        cursor: pointer;
    }
    .nav-list{
        z-index: 10;
        position: fixed;
        display:flex;
        height: 100vh;
        width:100%;
        background-color: #150b1dd0;
        left:0;
        margin: 0;
        transition: all ease .5s;
        flex-direction:column;
        align-items: center;
        text-align: center;
        backdrop-filter:blur(1px) ;
    }
    .nav-list.closed {
    left:-1000px;
    transition: all ease .5s;
}
    .nav-list li a {
        color : white;
    }
        .portals {
           top:auto;
        }
    
        .portals li {
    
            width: 200px;
    
        }
   
}



