@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed&display=swap');

$primary: #662f94;

.qoute{
    height: 100vh;
    background: radial-gradient(circle, rgba(186,167,203,1) 6%, rgba(171,137,199,1) 54%);
    font-family: 'Barlow Condensed', sans-serif;
    .logo{
        width: 45%;
       margin-left:auto;
       margin-right: auto;
    }
    .signup {
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom: 0;
        height:100%;
        width:100%;
        background-color: #00000034;
        backdrop-filter:blur(8px) ;
        .signupcontent{
            
            background-color: white;
            height: 60hv;
        }
    }
}