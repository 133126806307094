@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed&display=swap');
.root { 
  --primary : #662f94;
font-family: 'Barlow Condensed', sans-serif;
 font-size: 4vw
 
 }

.App {
  overflow: hidden;
  overflow-wrap: normal;
}
.contactUs {
  background-color: #f3f3f3;
}

.contactUs .top{
  padding: 0rem 2rem 2rem 2rem;
  color: rgb(0, 0, 0);
  display:flex;
  justify-content: center;
  align-items: center;
}
.contactUs .top .item{
  align-items: stretch;
}
.contactUs .top .item>div{
  text-align: center;
  padding:2rem 0;
  margin:2rem;
  height: 80%;
  width: 70%;
  background-color: white;
  border: solid 1px white;
  align-self: stretch;
}
.contactUs .top .item  h6{
  font-weight: 600;
  margin: 1rem;
}
.contactUs .top .item .MuiSvgIcon-root {
  font-size: 2.5rem;
  color: rgb(255, 255, 255);
  border: solid purple 1px;
  border-radius: 50%;
  padding:.5rem;
  background-color: #662f94;
}
.contactUs .form {
  padding:2rem;
}
.contactUs .form .row {
  width: 50%;
  padding:4rem 3rem;
  border-radius: 10px;
 
}
@media(max-width:768px) {
  .contactUs .form .row{
    width:100%;
  }
}
.contactUs .form .row .formqueryheader {
  font-size:2px;
  margin:1rem;
  padding:1rem;
  margin-left: 0;
  padding-left:0;
  height: 50%;
}

.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
  background-color: white;
}


.css-1480iag-MuiInputBase-root-MuiInput-root:after{
  border-bottom: #662f94 2px solid;
}
.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root{
  padding:2rem;
}

/* About US  */

.slider {
  height:180px;
  width: 100%;
  padding:2rem;
}
.value_chain {
  width: 100%;
  .items {
    display: grid;
    grid-template-columns: repeat(7,auto);
    overflow-x: auto;
    font-size: 1rem;
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin:0 .5rem 2rem .5rem;
      
      .heading {
        width: 100%;
        color:white;
        background-color:purple;
        padding:0;
        height: 30%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .body {
        background-color: white;
        margin-bottom:1rem;
        height: 100%;
        padding:1rem;
      }
      .bottom_line{
        height:.8rem;
        width: 100%;
        background-color: purple;
      }.vertical_line {
        height: .5rem;
        width: 2rem;
        margin:1rem;
        background-color: purple;
        transform: rotate(90deg);
      }
      .description {
        border:2px solid purple;
        width: 90%;
        border-radius: 6px;
        margin:0 0 1rem 0;
      }
    }
    @media screen and (max-width:'1028px') {
      grid-template-columns: repeat(7,auto);
      grid-template-rows: auto;
    }
    @media screen and (max-width:'768px') {
      grid-template-columns: repeat(7,auto);
      grid-template-rows: auto;
    }
    @media screen and (max-width:'428px') {
      grid-template-columns: repeat(7,auto);
      grid-template-rows: auto;
    }
    
  }
 .bottom {
  background-color: #662f94;
  color:white;
  padding:0 !important ;
  width: 100% !important;
  margin:0 !important;
 }
}