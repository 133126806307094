

.footer{
    background: #662f94;
    padding : 60px 0;
    font-family: 'Barlow Condensed', sans-serif;
    color:white;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.footer h5 {
    padding-bottom: 6px;
    margin-bottom: 20px;
    color: #ffffff;
}
.footer h4 {
    font-size: 16px;
}
.footer h4:hover {
    font-size: 20px;
    transition: all ease 0.3s;

}
.quick-links {
    list-style: none;
    padding: 0;
}
.quick-links> i  {
    background-color: #000;
}
.footer .fa { color: white !important }
.footer .fa:hover {
    font-size: 24px;
}
hr {
    z-index: 1;
}
.links a{
    text-decoration: none;
    color :white;
}